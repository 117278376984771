// ABI
import { getViemClients, viemClients } from '../utils/viem'
import { Abi, PublicClient, WalletClient, getContract as viemGetContract } from 'viem'
import { Address, erc20ABI, erc721ABI } from 'wagmi'

// Addresses
import { getRatRouletteAddress } from './addressHelpers'

// ABI
import bep20ABI from '../abis/erc20.json'
import multicallABI from '../abis/multicall.json'
import ratRouletteABI  from '../abis/ratroulette.json'

import { getPublicClient } from '@wagmi/core'

export const getContract = <TAbi extends Abi | unknown[], TWalletClient extends WalletClient>({
    abi,
    address,
    chainId = 31337,
    publicClient,
    signer,
}: {
    abi: TAbi
    address: Address
    chainId?: ChainId
    signer?: TWalletClient
    publicClient?: PublicClient
}) => {
    const c = viemGetContract({
        abi,
        address,
        publicClient: publicClient ?? viemClients[chainId],
        walletClient: signer,
    })
    return {
        ...c,
        account: signer?.account,
        chain: signer?.chain,
    }
}

export const getRatRouletteContract = (signer?: WalletClient) => {
    return getContract({ abi: ratRouletteABI, address: getRatRouletteAddress(31337), signer })
}

export const getBep20Contract = (address: Address, signer?: WalletClient) => {
    return getContract({ abi: bep20ABI, address, signer })
}

