import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"

import { Address, Connector } from '@wagmi/core'

export interface Web3ProviderState {
  Account: IAccount
  Chain: IChain
}

interface IAccount {
  address?: Address
  connector?: Connector
  isConnecting: boolean
  isReconnecting: boolean
  isConnected: boolean
  isDisconnected: boolean
  status: string
}

interface IChain {
  chainID: number
}

const initialState: Web3ProviderState = {
  Account: {
    address: undefined,
    isConnected: false,
    isConnecting: false,
    isDisconnected: true,
    isReconnecting: false,
    status: 'disconnected',
  },
  Chain: {
    chainID: 0
  }
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
//   },
// )

export const web3ProviderSlice = createSlice({
  name: "web3Provider",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateAccount: (state, action: PayloadAction<IAccount>) => {
      const { address, isConnected, isConnecting, isDisconnected, isReconnecting, status } = action.payload;
      state.Account.address = action.payload.address
      state.Account.isConnected = action.payload.isConnected
      state.Account.isConnecting = action.payload.isConnecting
      state.Account.isDisconnected = action.payload.isDisconnected
      state.Account.isReconnecting = action.payload.isReconnecting
      state.Account.status = action.payload.status

      // // Return a new state object with updated values
      // return { ...state, address, isConnected, isConnecting, isDisconnected, isReconnecting,status };
    },
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading"
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle"
  //       state.value += action.payload
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = "failed"
  //     })
  // },
})

export const { updateAccount } = web3ProviderSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.counter.value
export const selectAccount = (state: RootState) => state.web3Provider.Account.address

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState())
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount))
    }
  }

export default web3ProviderSlice.reducer
