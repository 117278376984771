import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  BetLocalSerialized,
  BetSerialized,
  BettingOption,
  DIRECTION_X,
  DIRECTION_Y,
  NUMBERS,
} from "../../types"
import { BetsState } from "../types"
import { getDecimalAmount } from "../../utils/formatBalance"
import BigNumber from "bignumber.js"
import { isOutsideBet } from "../../utils"
import { getHighlightedNumbers, getTransformedBets, highLightCornerBet, highLightSixLineBet, highLightSplitBetHorizontal, highLightSplitBetVertical, highLightStreetBet } from "./helpers"

const initialState: BetsState = Object.freeze({
  highlightedNumbers: [],
  selectedRound: 0,
  pendingOutsideBets: [],
  pendingInsideBets: [],
})

function findBetByOption(_betsList: BetSerialized[], _option: BettingOption) {
  return _betsList.findIndex((bet) => bet.option === _option)
}

function findBetByOptionAndNumber(
  _betsList: BetSerialized[],
  _option: BettingOption,
  _number: NUMBERS,
) {
  return _betsList.findIndex(
    (bet) => bet.option === _option && bet.number === _number,
  )
}

function updateOutsideBetState(state, existingBetIndex: number, amount: string) {
  const prevAmount = new BigNumber(state.pendingOutsideBets[existingBetIndex].amount)
  const newTotal = BigNumber.sum(prevAmount, amount)
  state.pendingOutsideBets[existingBetIndex].amount = newTotal.toString()
}

function updateInsideBetState(state, existingBetIndex: number, amount: string) {
  const prevAmount = new BigNumber(state.pendingInsideBets[existingBetIndex].amount)
  const newTotal = BigNumber.sum(prevAmount, amount)
  state.pendingInsideBets[existingBetIndex].amount = newTotal.toString()
}


export const BetsSlice = createSlice({
  name: "bets",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    incrementRound: (state) => {
      state.selectedRound += 1
    },
    decrementRound: (state) => {
      if (state.selectedRound > 0){
        state.selectedRound -= 1
      } 
    },
    updateHighlighedNumbersV2: (state, action: PayloadAction<BettingOption>) => {
      const payload = action.payload
      let numbersToHighlight = getHighlightedNumbers(payload)
      state.highlightedNumbers = numbersToHighlight
    },
    updateHighlightInsideBet: (state, action: PayloadAction<BetLocalSerialized>) => {
      const payload = action.payload
      if (payload.option == BettingOption.Straight){
        state.highlightedNumbers = [payload.number]
        return
      }
      if (payload.option == BettingOption.Split_Horizontal){
        state.highlightedNumbers = highLightSplitBetHorizontal(payload.number, payload.directionX)
        return
      }
      if (payload.option == BettingOption.Split_Vertical){
        console.log("highLightSplitBetVertical:: payload = ", payload)
        state.highlightedNumbers = highLightSplitBetVertical(payload.number, payload.directionY)
        return
      }
      if (payload.option == BettingOption.Street){
        state.highlightedNumbers = highLightStreetBet(payload.number)
        return
      }
      if (payload.option == BettingOption.Corner){
        state.highlightedNumbers = highLightCornerBet(payload.number, payload.directionX, payload.directionY)
        return
      }
      if (payload.option == BettingOption.SixLine){
        state.highlightedNumbers = highLightSixLineBet(payload.number, payload.directionX)
        return
      }
    },
    clearHighlights: (state) => {
      state.highlightedNumbers = []
    },
    updateBet: (state, action: PayloadAction<BetLocalSerialized>) => {
      const payload = action.payload
      const betAmount = getDecimalAmount(new BigNumber(action.payload.amount)).toString()
      payload.amount = betAmount

      if (isOutsideBet(action.payload.option)) {
        const existingBetIndex = findBetByOption(state.pendingOutsideBets, action.payload.option)
        if (existingBetIndex !== -1) {
          updateOutsideBetState(state, existingBetIndex, betAmount)
        } else {
          state.pendingOutsideBets.push(payload)
        }
        return
      } 
      
      let transformedBet = getTransformedBets(payload)
      const existingBetIndex = findBetByOptionAndNumber(state.pendingInsideBets, transformedBet.option, transformedBet.number)
      if (existingBetIndex !== -1) {
        updateInsideBetState(state, existingBetIndex, betAmount)
      } else {
        state.pendingInsideBets.push(payload)
      }

    },
    clearBets: (state) => { 
      state.pendingOutsideBets = []
      state.pendingInsideBets = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {},
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading"
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle"
  //       state.value += action.payload
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = "failed"
  //     })
  // },
})

// Actions
export const { 
  updateBet, 
  clearBets, 
  incrementRound, 
  decrementRound, 
  updateHighlighedNumbersV2, 
  clearHighlights,
  updateHighlightInsideBet
} = BetsSlice.actions

export default BetsSlice.reducer
