export default {
  ratRoulette: {
    97: '',
    56: '',
    31337: '0xe11e74EBc20CA5FE866e3eB9304a6142148953f7',
  },
  multiCall: {
    1: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    97: '0xcA11bde05977b3631167028862bE2a173976CA11',
    280: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    31337: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  }
} as const satisfies Record<string, Record<number, `0x${string}`>>