import BigNumber from "bignumber.js"
import { BetSerialized, BettingOption } from "../../../../types"
import styles from "./betcard.module.css"
import { getBettingOptionName, isOutsideBet } from "../../../../utils/index"
import { BIG_ZERO } from "../../../../config/constants/bigNumber"
import { getFullDisplayBalance } from "../../../../utils/formatBalance"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../../../../state"
import { clearHighlights, updateHighlighedNumbersV2, updateHighlightInsideBet } from "../../../../state/bets"

interface betProps {
  bet?: BetSerialized
  isConfirmed?: boolean
  hasWon?: boolean
}

export default function betCard({ bet, isConfirmed, hasWon }: betProps) {
  const dispatch = useAppDispatch()
  
  const [isCardHidden, setisCardHidden] = useState(true);

  const betTypeName = bet ? getBettingOptionName(bet.option) : ""
  const _isOutsideBet = isOutsideBet(bet?.option)
  const betNumberText = _isOutsideBet ? '' : bet.number

  const id = `${betTypeName}${bet?.number}`
  const amountBet = new BigNumber(bet?.amount)
  const amountBetDisplay = getFullDisplayBalance(amountBet)

  const confirmedText = isConfirmed ? "Confirmed" : "Not Confirmed"
  const wonText = hasWon ? "Win!" : ""

  function onMouseEnter(event: Event){
    console.log("onMouseEnter: bet ", bet)
    console.log("onMouseEnter: _isOutsideBet", _isOutsideBet)

    if (bet?.amount == 0){
      return
    }

    if (_isOutsideBet || bet?.option == BettingOption.Basket){
      dispatch(updateHighlighedNumbersV2(bet?.option))
      return
    }
    dispatch(updateHighlightInsideBet(bet))

  }

  function onMouseExit(){
    dispatch(clearHighlights())
  }

  useEffect(() => {
    if (bet?.amount > 0) {
      setisCardHidden(false)
    } else {
      setisCardHidden(true)
    }
  }, [bet]);

  return (
    <label className={`${styles.card}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseExit}
    >
      <input type="checkbox" id={id} checked={isCardHidden} />
      <div className={`${styles.back}`}></div>
      <div className={`${styles.front}`}>
        <div>{betTypeName}</div>
        <div>{betNumberText}</div>
        <div>{wonText}</div>
        <div>{amountBetDisplay} ETH</div>
      </div>
    </label>
  )
}
