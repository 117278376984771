import styles from "./chip.module.css"

interface chipProps {
  amount: number
  bgNumber: number
  isSelected?: boolean
  onClickCallback: (
    event: any /*React.MouseEvent<HTMLDivElement>*/
  ) => void;
}

export default function chip({ amount, bgNumber, isSelected, onClickCallback }: chipProps) {

  const bgSelect = `chip__${bgNumber}`;
  const selectCall = `chip__isSelected`;

  return (
    <div className={`${styles.chip} ${styles[bgSelect]} ${isSelected ? styles[selectCall] : ''}`} onClick={onClickCallback} data-amount={amount}>
      <div>{amount}</div>
    </div>
  );
}