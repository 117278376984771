import { TransitionGroup } from "react-transition-group";
import { ToastContainerProps } from "./types";
import Toast from "react-bootstrap/Toast";
import ToastContainer from 'react-bootstrap/ToastContainer';

export const ToastWrap: React.FC<React.PropsWithChildren<ToastContainerProps>> = ({
  toasters,
  onRemove
}) => {

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="position-fixed"
      style={{ minHeight: '240px', top: 100, right: 20, zIndex: 1, height: '200px', width: '300px' }}
    >
      <ToastContainer position="top-end">
          {toasters.map((toast, index) => {
            return (
              <Toast key={index} bg={'danger'} delay={3000} autohide onClose={()=>{
                onRemove(toast.id)
              }}>
                <Toast.Header>
                  <strong className="me-auto">{toast.title}</strong>
                </Toast.Header>
                <Toast.Body>{toast.description}</Toast.Body>
              </Toast>
            );
          })}
        </ToastContainer>
    </div>
  );
};

