import { Web3Button } from "@web3modal/react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { LinkContainer } from "react-router-bootstrap"

export default function Navigation() {
  return (
    <Navbar expand="lg" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Rat.Bet</Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <LinkContainer to="account">
              <Nav.Link>Account</Nav.Link>
            </LinkContainer>

            <Web3Button />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
