import { ChainId } from '../types'
// import { atom, useAtomValue } from 'jotai'
// import { useRouter } from 'next/router'
import { useDeferredValue } from 'react'
// import { isChainSupported } from 'utils/wagmi'
import { useNetwork } from 'wagmi'
import { getChainId } from '../config/chains'
// import { useSessionChainId } from './useSessionChainId'

export const useActiveChainId = () => {
    // const localChainId = useLocalNetworkChain()
    // const queryChainId = useAtomValue(queryChainIdAtom)
  
    const { chain } = useNetwork()
    const chainId = 31337
    // const chainId = localChainId ?? chain?.id ?? (queryChainId >= 0 ? ChainId.BSC : undefined)

  
    // const isNotMatched = useDeferredValue(chain && localChainId && chain.id !== localChainId)

    const isNotMatched = false

    return {
      chainId,
      isWrongNetwork: (chain?.unsupported ?? false) || isNotMatched,
      isNotMatched,
    }
  }