import Styles from "./legend.module.css"

export default function Legend() {
  return (
    <div className={Styles.legend}>
      <div className={`${Styles.legendItem} ${Styles.legend__confirmed}`}>
        Confirmed
      </div>
      <div className={`${Styles.legendItem} ${Styles.legend__pending}`}>
        Pending
      </div>
      <div className={`${Styles.legendItem} ${Styles.legend__won}`}>
        Won
      </div>
    </div>
  )
}
