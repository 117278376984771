import { createSelector } from '@reduxjs/toolkit'
import { State } from '../../state/types'
import { transformRatRoulettePublicData, transformRatRouletteUserData } from './helpers'

const selectRatRoulettePublicData = (state: State) => state.ratRoulette.publicData
const selectRatRouletteUserData = (state: State) => state.ratRoulette.userData

export const ratRouletteDataSelector = createSelector(
  [selectRatRoulettePublicData, selectRatRouletteUserData],
  (publicData, userData) => {
    return {
      publicData: transformRatRoulettePublicData(publicData),
      userData: transformRatRouletteUserData(userData),
    }
  },
)