import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import { useDeposit } from "./hooks/useDeposit"
import { useState } from "react"
import AccountCard from "../BettingTable/components/AccountCard/accountCard"

const Account: React.FC<React.PropsWithChildren> = () => {
  const [amount, setAmount] = useState<number>(0)

  let { handleDeposit } = useDeposit(amount)

  return (
    <Row style={{ position: "relative" }}>
      <AccountCard></AccountCard>
      <Col md={4}>
        <div className="account">
          <div>
            <h1>DEPOSIT</h1>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                placeholder="Amount"
                aria-label="Amount"
                aria-describedby="basic-addon2"
                onChange={(event) => {
                  setAmount(event?.target.value)
                }}
              />
              <Button
                variant="outline-secondary"
                id="button-addon2"
                onClick={handleDeposit}
              >
                Deposit
              </Button>
            </InputGroup>
          </div>
          <div>
            <h1>WITHDRAW</h1>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Account
