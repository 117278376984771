import BigNumber from 'bignumber.js'
import { getRatRouletteAddress } from '../../utils/addressHelpers'
import RatRouletteABI from '../../abis/ratroulette.json'
import { publicClient } from '../../provider'
import { Address, useAccount } from 'wagmi'
import { Bet } from '../../types'

const BIG_ZERO = new BigNumber(0)

export const fetchUserRatRouletteData = async (account: Address , roundNumber: Number) => {
    console.log("fetchUserRatRouletteData: ", roundNumber)
    try {
        const calls = [
            {
                abi: RatRouletteABI,
                address: getRatRouletteAddress(31337),
                functionName: 'getUserAccount',
                args: [account],
            },
            {
                abi: RatRouletteABI,
                address: getRatRouletteAddress(31337),
                functionName: 'getUsersOutsideBets',
                args: [account, roundNumber],
            },
            {
                abi: RatRouletteABI,
                address: getRatRouletteAddress(31337),
                functionName: 'getUsersInsideBets',
                args: [account, roundNumber],
            },
            {
                abi: RatRouletteABI,
                address: getRatRouletteAddress(31337),
                functionName: 'getWinningBets',
                args: [account, roundNumber],
            },
            {
                abi: RatRouletteABI,
                address: getRatRouletteAddress(31337),
                functionName: 'getClaimedRound',
                args: [account, roundNumber],
            },
        ]
        
        const client = publicClient(31337)
        const [
            userAccount,
            usersOusideBets,
            usersInsideBets,
            usersWinningBets,
            userClaimedRound
        ] = await client.multicall({
            contracts: calls,
            allowFailure: false,
        })

        const outsideBetsSerialized = usersOusideBets.map((bet: Bet) => ({
          ...bet,
          amount: new BigNumber(bet.amount.toString()).toJSON()
        })); 

        const insideBetsSerialized = usersInsideBets.map((bet: Bet) => ({
            ...bet,
            amount: new BigNumber(bet.amount.toString()).toJSON()
        })); 

        const serializedWinningBets = usersWinningBets.map((bet: Bet) => ({
            ...bet,
            amount: new BigNumber(bet.amount.toString()).toJSON()
          })); 

        console.log("userAccount: ", userAccount)
        console.log("usersOusideBets: ", usersOusideBets)
        console.log("usersInsideBets: ", usersInsideBets)
        console.log("usersWinningBets: ", usersWinningBets)
        console.log("userClaimedRound: ", userClaimedRound)

        return {
            account: {
                balance: new BigNumber(userAccount.balance.toString()).toJSON(),
                totalBet: new BigNumber(userAccount.totalBet.toString()).toJSON(),
                totalWon: new BigNumber(userAccount.totalWon.toString()).toJSON(),
            },
            bets: {
                placed: [...outsideBetsSerialized, ...insideBetsSerialized],
                pendingWins: [...serializedWinningBets]
            }
        }
    } catch (e) {
        console.error("fetchUserRatRouletteData", e)
        return {
            usersBets: {},
        }
    }
}