export const getNodeRealUrl = (networkName: string) => {
    let host = null
  
    switch (networkName) {
      case 'homestead':
        if (import.meta.env.NEXT_PUBLIC_NODE_REAL_API_ETH) {
          host = `eth-mainnet.nodereal.io/v1/${import.meta.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`
        }
        break
      case 'goerli':
        if (import.meta.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI) {
          host = `eth-goerli.nodereal.io/v1/${import.meta.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`
        }
        break
      default:
        host = null
    }
  
    if (!host) {
      return null
    }
  
    const url = `https://${host}`
    return {
      http: url,
      webSocket: url.replace(/^http/i, 'wss').replace('.nodereal.io/v1', '.nodereal.io/ws/v1'),
    }
  }
  
  export const getNodeRealUrlV2 = (chainId: number, key?: string) => {
    let host = null
  
    switch (chainId) {
      case 1:
        if (key) {
          host = `eth-mainnet.nodereal.io/v1/${key}`
        }
        break
      case 5:
        if (key) {
          host = `eth-goerli.nodereal.io/v1/${key}`
        }
        break
      case 56:
        if (key) {
          host = `bsc-mainnet.nodereal.io/v1/${key}`
        }
        break
      default:
        host = null
    }
  
    if (!host) {
      return null
    }
  
    const url = `https://${host}`
    return url
  }