import { BetLocalSerialized, BettingOption, DIRECTION_X, DIRECTION_Y, NUMBERS } from "../../types"

export function getTransformedBets(bet: BetLocalSerialized){
  if (
    bet.option === BettingOption.Straight ||
    bet.option === BettingOption.Street
  ) {
    return bet
  }
  if (bet.option === BettingOption.Split_Vertical){
    return transformSplitVerticalBet(bet)
  }
  if (bet.option === BettingOption.Split_Horizontal){
    return transformSplitHorizontalBet(bet)
  }
  if (bet.option === BettingOption.Corner){
    return transformCornerBet(bet)
  }
  if (bet.option === BettingOption.SixLine){
    return transformSixLineBet(bet)
  }
  if (bet.option === BettingOption.Basket){
    return transformBasketBet(bet)
  }

}

function transformSplitVerticalBet(payload: BetLocalSerialized){
  const payloadcopy = payload

  // handle 0, and 00
  if (payload.number == NUMBERS.ZERO || payload.number == NUMBERS.DOUBLE_ZERO){
    payloadcopy.number = NUMBERS.ZERO
  } else if (payload.directionY == DIRECTION_Y.TOP){
    payloadcopy.number = payload.number - 1
  }

  return payloadcopy 
}

function transformSplitHorizontalBet(payload: BetLocalSerialized){
  const payloadcopy = payload

  if (payload.directionX == DIRECTION_X.RIGHT){
    payloadcopy.number = payload.number - 3
  }

  return payloadcopy 
}

function transformCornerBet(payload: BetLocalSerialized) {
  const payloadcopy = payload

  // top row
  if (payload.number % 3 == 1 && payload.directionX == DIRECTION_X.RIGHT) {
    payloadcopy.number -= 3
    return payloadcopy
  }

  // its bottom row
  if (payload.number % 3 == 0 && payload.directionX == DIRECTION_X.RIGHT) {
    payloadcopy.number -= 4
    return payloadcopy
  }
  if (payload.number % 3 == 0 && payload.directionX == DIRECTION_X.LEFT) {
    payloadcopy.number -= 1
    return payloadcopy
  }

  // its middle row
  if (payload.number % 3 == 2) {
    if (
      payload.directionY == DIRECTION_Y.TOP &&
      payload.directionX == DIRECTION_X.LEFT
    ) {
      payloadcopy.number -= 1
      return payloadcopy
    }
    if (
      payload.directionY == DIRECTION_Y.TOP &&
      payload.directionX == DIRECTION_X.RIGHT
    ) {
      payloadcopy.number -= 4
      return payloadcopy
    }
    if (
      payload.directionY == DIRECTION_Y.BOTTOM &&
      payload.directionX == DIRECTION_X.RIGHT
    ) {
      payloadcopy.number -= 3
      return payloadcopy
    }
    if (
      payload.directionY == DIRECTION_Y.BOTTOM &&
      payload.directionX == DIRECTION_X.LEFT
    ) {
      return payloadcopy
    }
  }

  // if (payload.directionX == DIRECTION_X.LEFT && payload.directionY == DIRECTION_Y.BOTTOM){

  // }

  return payloadcopy
}

function transformSixLineBet(payload: BetLocalSerialized){
  const payloadcopy = payload

  if (payload.directionX == DIRECTION_X.RIGHT){
    payload.number = payload.number - 3
  }

  return payloadcopy 
}

function transformBasketBet(payload: BetLocalSerialized){
  const payloadcopy = payload

  payload.number = NUMBERS.TWO

  return payloadcopy 
}

export function getHighlightedNumbers(bettingOption: BettingOption) {
  if (bettingOption == BettingOption.Red) {
    return [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36]
  }
  if (bettingOption == BettingOption.Black) {
    return [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35]
  }
  if (bettingOption == BettingOption.Odd) {
    return [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35]
  }
  if (bettingOption == BettingOption.Even) {
    return [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36]
  }
  if (bettingOption == BettingOption.Low) {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
  }
  if (bettingOption == BettingOption.High) {
    return [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
  }
  if (bettingOption == BettingOption.Columns1) {
    return [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34]
  }
  if (bettingOption == BettingOption.Columns2) {
    return [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35]
  }
  if (bettingOption == BettingOption.Columns3) {
    return [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36]
  }
  if (bettingOption == BettingOption.Dozens1) {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  }
  if (bettingOption == BettingOption.Dozens2) {
    return [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
  }
  if (bettingOption == BettingOption.Dozens3) {
    return [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]
  }
  if (bettingOption == BettingOption.Basket) {
    return [0, 1, 2, 3, 37]
  }
  return []
}

export function highLightSplitBetHorizontal(number: NUMBERS, directionX: DIRECTION_X){
  if (directionX == DIRECTION_X.LEFT){
    return [number, number + 3]
  }
  if (directionX == DIRECTION_X.RIGHT ){
    return [number, number - 3]
  }
  return []
}

export function highLightSplitBetVertical(number: NUMBERS, directionY: DIRECTION_Y){
  if (number == NUMBERS.ZERO || number == NUMBERS.DOUBLE_ZERO){
    return [NUMBERS.ZERO, NUMBERS.DOUBLE_ZERO]
  }

  if (directionY == DIRECTION_Y.TOP){
    return [number, number - 1]
  }
  if (directionY == DIRECTION_Y.BOTTOM ){
    return [number, number + 1]
  }
  return []
}

export function highLightStreetBet(number: NUMBERS){
  return [number, number -1, number - 2]
}

export function highLightCornerBet(number: NUMBERS, directionX: DIRECTION_X, directionY: DIRECTION_Y){

  if (directionX == DIRECTION_X.LEFT && directionY == DIRECTION_Y.TOP){
    return [number - 1, number, number + 2, number + 3 ]
  }

  if (directionX == DIRECTION_X.RIGHT && directionY == DIRECTION_Y.TOP){
    return [number - 1, number, number - 3, number - 4 ]
  }

  if (directionX == DIRECTION_X.LEFT && directionY == DIRECTION_Y.BOTTOM){
    return [number, number + 1, number + 3, number + 4 ]
  }

  if (directionX == DIRECTION_X.RIGHT && directionY == DIRECTION_Y.BOTTOM){
    return [number, number + 1, number - 3, number - 2 ]
  }
  return []
}

export function highLightSixLineBet(number: NUMBERS, directionX: DIRECTION_X){
  if (directionX == DIRECTION_X.LEFT){
    return [number - 2, number - 1, number, number + 1, number + 2, number + 3]
  }
  if (directionX == DIRECTION_X.RIGHT){
    return [number - 5, number - 4, number - 3, number - 2, number - 1, number]
  }
  return []
}