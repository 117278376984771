import { Abi, Address } from 'viem'
import { erc20ABI, usePublicClient, useWalletClient } from 'wagmi'

import { useActiveChainId } from '../hooks/useActiveChainId'

import addresses from '../config/constants/contracts'
import { useMemo } from 'react'
import {
  getRatRouletteContract,
  getBep20Contract,
} from '../utils/contractHelpers'

import { ChainId } from '../types'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: Address) => {
  return useContract(address, erc20ABI)
}

export const useRatRouletteContract = () => {
  const { data: signer } = useWalletClient()
  return useMemo(() => getRatRouletteContract(signer), [signer])
}

// Code below migrated from Exchange useContract.ts

// // returns null on errors
// export function useContract<TAbi extends Abi>(
//   addressOrAddressMap?: Address | { [chainId: number]: Address },
//   abi?: TAbi,
// ) {
//   const { chainId } = useActiveChainId()
//   const { data: walletClient } = useWalletClient()

//   return useMemo(() => {
//     if (!addressOrAddressMap || !abi || !chainId) return null
//     let address: Address | undefined
//     if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
//     else address = addressOrAddressMap[chainId]
//     if (!address) return null
//     try {
//       return getContract({
//         abi,
//         address,
//         chainId,
//         signer: walletClient,
//       })
//     } catch (error) {
//       console.error('Failed to get contract', error)
//       return null
//     }
//   }, [addressOrAddressMap, abi, chainId, walletClient])
// }

// export function useTokenContract(tokenAddress?: Address) {
//   return useContract(tokenAddress, erc20ABI)
// }