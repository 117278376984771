import { useRatRouletteData } from '../../../../state/roulette/hooks'
import { getFullDisplayBalance } from '../../../../utils/formatBalance'
import styles from './accountCard.module.css'

export default function accountCard() {
  const { userData } = useRatRouletteData()
  // className:`${style.accountCard}`
  return (
    <div className={`${styles.AccountCard}`} id='top'>
        <h4>Balance: {getFullDisplayBalance(userData.account.balance)} ETH</h4>
        <h4>Total Bet: {getFullDisplayBalance(userData.account.totalBet)} ETH</h4>
        <h4>Total Won: {getFullDisplayBalance(userData.account.totalWon)} ETH</h4>
    </div>
  )
}
