import { useCallback } from 'react'
import { useAppDispatch } from '../../../state'
import { useToast } from '../../../contexts/ToastsContext'
import useCatchTxError from '../../../hooks/useCatchTxError'
// import { ToastDescriptionWithTx } from 'components/Toast'
import { useRatRouletteContract } from '../../../hooks/useContract'
import { Address, useAccount } from 'wagmi'
import { useBetsData } from '../../../state/bets/hooks'

import { fetchPublicRatRouletteDataAsync, fetchUserRatRouletteDataAsync } from '../../../state/roulette'

export const useClaimRoundWinnings = () => {
  const dispatch = useAppDispatch()
  const { betsData } = useBetsData()
  const { address: account } = useAccount()
  
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: isPending } = useCatchTxError()
  const contract = useRatRouletteContract()

  const handleClaimRoundWinnings = useCallback(async () => {
    // await contract.write.setRoundWinner(['6'])

     const receipt = await fetchWithCatchTxError(() =>
      contract.write.claimRoundWinnings([0]),
    )

    if (receipt?.status) {
      toastSuccess(
        'Success!',
        'Claimed Winnings'
        // <ToastDescriptionWithTx txHash={receipt.transactionHash}>
        //   {'Your funds have been staked in the pool'}
        // </ToastDescriptionWithTx>,
      )
      // dispatch(fetchPotteryUserDataAsync(account))
      dispatch(fetchPublicRatRouletteDataAsync())
      dispatch(fetchUserRatRouletteDataAsync(account))
    }
  // }, [amount, fetchWithCatchTxError, contract.write, account, chain, toastSuccess, t, dispatch])
  }, [fetchWithCatchTxError, contract.write, dispatch, account])

  return { isPending, handleClaimRoundWinnings }
}