import { BetDeserialized } from "../../../../types"
import BetCard from "../BetCard/betcard"
import RoundSelector from "../RoundSelector/roundSelector"
import Styles from "./BetsPlaced.module.css"
import Legend from "../Legend/legend"
import { Col, Container, Row } from "react-bootstrap"
import ControlPanel from "../ControlPanel/controlPanel"
import CardList from "../CardList/cardlist"

const BlankBet = {
  amount: 0,
  direction: 0,
  directionX: 0,
  directionY: 0,
  number: 0,
  option: 0,
}

export default function BetsPlaced() {

  return (
    <div className={Styles.betsWrapper}>
      <Row>
        <Col lg="10">
          <CardList></CardList>
          <Legend />
        </Col>
        <Col xs lg="2">
          <ControlPanel></ControlPanel>
        </Col>
      </Row>
      {/* <RoundSelector /> */}
      
      <div className={Styles.PlacedBets}>
        {/* {AllBets.length == 0 ? "No bets placed" : ""} */}
      </div>
    </div>
  )
}
