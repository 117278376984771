import { betBlockSize } from "../config/constants"
import { useAppDispatch } from "../state"
import { updateHighLightNumbers } from "../state/bets"
import { BettingOption, NUMBERS } from "../types"

export function getBettingOptionName(option: BettingOption): string {
  if (
    option === BettingOption.Split_Horizontal ||
    option === BettingOption.Split_Vertical
  ) {
    return "Split"
  }

  return BettingOption[option]
}

export function isTopEdge(number: NUMBERS) {
  return Number(number) % 3 == 1
}
export function isBottomEdge(number: NUMBERS) {
  return Number(number) % 3 == 0
}
export function isLeftEdge(number: NUMBERS) {
  return Number(number) > 33
}
export function isRightEdge(number: NUMBERS) {
  return Number(number) < 4
}

export const betBlockHoverLimit = betBlockSize / 3

export function isLeft(x: number) {
  return x < betBlockHoverLimit
}
export function isRight(x: number) {
  return x > betBlockSize - betBlockHoverLimit
}
export function isTop(y: number) {
  return y < betBlockHoverLimit
}
export function isBottom(y: number) {
  return y > betBlockSize - betBlockHoverLimit
}

export function isTopMiddle(x: number, y: number) {
  return isTop(y) && !isLeft(x) && !isRight(x)
}
export function isBottomMiddle(x: number, y: number) {
  return isBottom(y) && !isLeft(x) && !isRight(x)
}
export function isLeftMiddle(x: number, y: number) {
  return isLeft(x) && !isTop(y) && !isBottom(y)
}
export function isRightMiddle(x: number, y: number) {
  return isRight(x) && !isTop(y) && !isBottom(y)
}
export function isLeftTopCorner(x: number, y: number) {
  return isTop(y) && isLeft(x)
}
export function isLeftBottomCorner(x: number, y: number) {
  return isBottom(y) && isLeft(x)
}
export function isRightBottomCorner(x: number, y: number) {
  return isBottom(y) && isRight(x)
}
export function isOutsideBet(_betOption: BettingOption) {
  return _betOption < BettingOption.Straight
}