import { createSelector } from '@reduxjs/toolkit'
import { State } from '../../state/types'

const selectBetsData = (state: State) => state.bets
// const selectHighLightNumbers = (state: State) => state.bets.highlightedNumbers

export const betsDataSelector = createSelector(
    [selectBetsData],
    (betsData) => {
      return {
        betsData: betsData,
      }
    },
  )