import BigNumber from 'bignumber.js'
import { BIG_ZERO } from '../../config/constants/bigNumber'
import {
  SerializedRatRouletteUserData,
  DeserializedRatRouletteUserData,
  SerializedRatRoulettePublicData,
  DeserializedRatRoulettePublicData,
} from '../../state/types'
import { BetSerialized } from '../../types'

export const transformRatRoulettePublicData = (publicData: SerializedRatRoulettePublicData): DeserializedRatRoulettePublicData => {
  const { minBet, maxBet } = publicData

  return {
    ...publicData,
    minBet: minBet ? new BigNumber(minBet) : BIG_ZERO,
    maxBet: maxBet ? new BigNumber(maxBet) : BIG_ZERO,
  }
}

export const transformRatRouletteUserData = (userData: SerializedRatRouletteUserData): DeserializedRatRouletteUserData => {
  const { account, bets } = userData;

  const deserializeNumber = (value: string | undefined) => value ? new BigNumber(value) : BIG_ZERO;
  
  const deserializedAccount = {
    balance: deserializeNumber(account?.balance),
    totalBet: deserializeNumber(account?.totalBet),
    totalWon: deserializeNumber(account?.totalWon),
  };

  const deserializedBets = bets?.placed ? bets.placed.map((bet: BetSerialized) => ({
    ...bet,
    amount: deserializeNumber(bet.amount),
  })) : [];

  const deserializedWinBets = bets?.pendingWins ? bets.pendingWins.map((bet: BetSerialized) => ({
    ...bet,
    amount: deserializeNumber(bet.amount),
  })) : [];

  return {
    account: deserializedAccount,
    bets: {
      placed: deserializedBets,
      pendingWins: deserializedWinBets
    },
    // placedBets: [],
  };
}