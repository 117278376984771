import { useEffect } from 'react'
import { useAppDispatch } from "../../state"
import { fetchPublicRatRouletteDataAsync, fetchUserRatRouletteDataAsync } from './index'
import { useAccount } from 'wagmi'
import { useFastRefreshEffect } from '../../hooks/userRefreshEffect'
import { useSelector } from 'react-redux'
import { ratRouletteDataSelector } from './selectors'
// import useRefresh from "../../hooks/useRefresh";

export const updateUserRatRoulette = () => {
  const { address: account } = useAccount()
  const dispatch = useAppDispatch()
  const { publicData } = useRatRouletteData()

  console.log("updateUserRatRoulette")

  if (account){
    console.log("updateUserRatRoulette account")
    dispatch(fetchUserRatRouletteDataAsync({account}))
  }

}

export const useRatRouletteFetch = () => {
  const { address: account } = useAccount()
  const dispatch = useAppDispatch()
  
  useFastRefreshEffect(() => {
    dispatch(fetchPublicRatRouletteDataAsync())

    if (account){
      dispatch(fetchUserRatRouletteDataAsync(account))
    }

    // if (potteryVaultAddress && chainId) {
    //   batch(() => {
    //     dispatch(fetchCakeVaultPublicData(chainId))
    //     dispatch(fetchPublicPotteryDataAsync())
    //     if (account) {
    //       dispatch(fetchPotteryUserDataAsync(account))
    //       dispatch(fetchCakeVaultUserData({ account, chainId }))
    //     }
    //   })
    // }
  }, [ dispatch ])
}

export const useRatRouletteData = () => {
  return useSelector(ratRouletteDataSelector)
}

