import { useBetsData } from "../../../../state/bets/hooks"
import { useRatRouletteData } from "../../../../state/roulette/hooks"
import BetCard from "../BetCard/betcard"
import Styles from "./cardlist.module.css"

const BlankBet = {
    amount: 0,
    direction: 0,
    directionX: 0,
    directionY: 0,
    number: 0,
    option: 0,
  }

export default function CardList() {

    const { userData } = useRatRouletteData()
    const { betsData } = useBetsData()
    
    const numberOfCards = 8

    const AllBets = [
      ...userData.bets.placed,
      ...betsData.pendingOutsideBets,
      ...betsData.pendingInsideBets,
    ]

    const pendingBets = [...betsData.pendingOutsideBets, ...betsData.pendingInsideBets]

    function optionAlreadyConfirmed(betsArray, bet) {
        return betsArray.some(
          (_bet) => _bet.option === bet.option && _bet.number === bet.number,
        )
      }

    const CardList = function(){
        let arr = []
        AllBets.forEach(bet => {
          arr.push(bet)
        })
    
        for (let i = 0; i < numberOfCards - AllBets.length; i++){
          arr.push(BlankBet)
        }
        return arr
      }

  return (
   <div className={Styles.CardList}>
        {CardList().map((bet) => (
            <BetCard
                bet={bet}
            // isConfirmed={optionAlreadyConfirmed(pendingBets, bet)}
            // hasWon={optionAlreadyConfirmed(pendingWinningBets, bet)}
            ></BetCard>
        ))}
   </div>
  );
}