import { ReactNode } from "react";

export const types = {
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  INFO: "info",
};

export type Types = (typeof types)[keyof typeof types];

export interface ToastData {
  id: string;
  type: Types;
  title: string;
  description?: ReactNode;
}

export interface ToastContainerProps {
  toasters: ToastData[];
  onRemove: (id: string) => void;
}

export interface ToastProps {
  toast: ToastData;
  onRemove: ToastContainerProps["onRemove"];
}