import "./App.css"
import BettingTable from "./views/BettingTable"
import { Container, Row, Col } from "react-bootstrap"
import Navigation from "./components/NavBar/navbar"
import { Routes, Route } from "react-router-dom"
import Account from "./views/Account/account"

function App() {
  return (
    <div className="App">
      <Container fluid>
        <Navigation></Navigation>
        <main>
          <Row>
            <Col xs={12}>
              <Container className="mt-4">
                <Routes>
                    <Route path="/" element={<BettingTable />} />
                    <Route path="account" element={<Account />} />
                    {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                    {/* <Route path="*" element={<NoMatch />} /> */}
          
                </Routes>
                {/* <Stream></Stream> */}
              </Container>
            </Col>
          </Row>
        </main>
      </Container>
    </div>
  )
}

export default App
