import { useSelector } from "react-redux"
import { betsDataSelector } from "../bets/selectors"
import { useAppDispatch } from ".."
import { clearBets } from "./index"

export const useClearBets = () => {
    const dispatch = useAppDispatch()
    dispatch(clearBets())
}
  
export const useBetsData = () => {
    return useSelector(betsDataSelector)
}

export const useHighlightNumbers = () => {
    return useSelector(betsDataSelector)
}