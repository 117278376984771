import { Button, Col, Form, InputGroup, Row, Stack } from "react-bootstrap";
import styles from "./controlPanel.module.css"
import { useAppDispatch } from "../../../../state";
import { useClaimRoundWinnings } from "../../hooks/useClaimRoundWinnings";
import { useCloseRound } from "../../hooks/useCloseRound";
import { useSetRoundWinner } from "../../hooks/useSetRoundWinner";
import { clearBets } from "../../../../state/bets";
import { useBetsData } from "../../../../state/bets/hooks";
import { usePlaceBets } from "../../hooks/usePlaceBets";
import { useRatRouletteData } from "../../../../state/roulette/hooks";

export default function controlPanel() {
  const dispatch = useAppDispatch()
  
  const { betsData } = useBetsData()
  const { userData } = useRatRouletteData()
  const { isPending, handlePlaceBets } = usePlaceBets()
  const { handleClaimRoundWinnings } = useClaimRoundWinnings()
  const { handleCloseRound } = useCloseRound()
  const { handleSetRoundWinner } = useSetRoundWinner()

  function clearPendingBets() {
    dispatch(clearBets())
  }

  const buttonDisabled = (betsData.pendingOutsideBets.length == 0 && betsData.pendingInsideBets.length == 0)
  const claimButtonDisabled = userData.bets.pendingWins.length == 0

  return (
    <div className={styles.betsControls}>
      <Stack gap={2}>
        <Button disabled={buttonDisabled} onClick={handlePlaceBets}>Place Bets</Button>
        <Button disabled={buttonDisabled} onClick={clearPendingBets}>Clear Bets</Button>
        <Button disabled={claimButtonDisabled} onClick={handleClaimRoundWinnings}>Claim Winnings</Button>

        <Form.Group controlId="formGroupEmail">
          <Form.Label>Selected Round</Form.Label>
          <InputGroup>
        <Form.Control value={0} disabled/>
        <Button variant="primary">+</Button>
        <Button variant="primary">-</Button>
      </InputGroup>
        </Form.Group>


      </Stack>
      

    {/* <Stack direction="horizontal" gap={1}>

      <Button onClick={handleCloseRound}>Close Round</Button>
      <Button onClick={handleSetRoundWinner}>Set Round Winner</Button>

    </Stack> */}
    </div>
  );
}