import { useCallback } from 'react'
import { useAppDispatch } from '../../../state'
import { useToast } from '../../../contexts/ToastsContext'
import useCatchTxError from '../../../hooks/useCatchTxError'
import { useRatRouletteContract } from '../../../hooks/useContract'
import { fetchPublicRatRouletteDataAsync, fetchUserRatRouletteDataAsync } from '../../../state/roulette'
import { useAccount } from 'wagmi'

export const useCloseRound = () => {
  const dispatch = useAppDispatch()

  const { address: account } = useAccount()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: isPending } = useCatchTxError()
  const contract = useRatRouletteContract()

  const handleCloseRound = useCallback(async () => {
    // await contract.write.setRoundWinner(['6'])

     const receipt = await fetchWithCatchTxError(() =>
      contract.write.closeRound(),
    )

    if (receipt?.status) {
      toastSuccess(
        'Success!',
        'Round Closed'
        // <ToastDescriptionWithTx txHash={receipt.transactionHash}>
        //   {'Your funds have been staked in the pool'}
        // </ToastDescriptionWithTx>,
      )
      // dispatch(fetchPotteryUserDataAsync(account))
      dispatch(fetchPublicRatRouletteDataAsync())
      dispatch(fetchUserRatRouletteDataAsync(account))
    }
  // }, [amount, fetchWithCatchTxError, contract.write, account, chain, toastSuccess, t, dispatch])
  }, [fetchWithCatchTxError, contract.write, dispatch, account])

  return { isPending, handleCloseRound }
}