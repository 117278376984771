import BigNumber from "bignumber.js"

export enum NUMBERS {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  EIGHT,
  NINE,
  TEN,
  ELEVEN,
  TWELVE,
  THIRTEEN,
  FOURTEEN,
  FIFTEEN,
  SIXTEEN,
  SEVENTEEN,
  EIGHTEEN,
  NINETEEN,
  TWENTY,
  TWENTY_ONE,
  TWENTY_TWO,
  TWENTY_THREE,
  TWENTY_FOUR,
  TWENTY_FIVE,
  TWENTY_SIX,
  TWENTY_SEVEN,
  TWENTY_EIGHT,
  TWENTY_NINE,
  THIRTY,
  THIRTY_ONE,
  THIRTY_TWO,
  THIRTY_THREE,
  THIRTY_FOUR,
  THIRTY_FIVE,
  THIRTY_SIX,
  DOUBLE_ZERO, // 37
}

export enum BettingOption {
  Red,
  Black,
  Even,
  Odd,
  High,
  Low,
  Columns1,
  Columns2,
  Columns3,
  Dozens1,
  Dozens2,
  Dozens3,
  Straight,
  Split_Horizontal,
  Split_Vertical,
  Street,
  Corner,
  SixLine,
  Basket,
}

export enum DIRECTION {
  HORIZONTAL,
  VERTICAL,
}
export enum DIRECTION_X {
  LEFT,
  RIGHT,
}
export enum DIRECTION_Y {
  TOP,
  BOTTOM,
}

export interface Bet {
  option: BettingOption
  number: NUMBERS
  amount: string
}

export interface BetSerialized {
  option: BettingOption
  number: NUMBERS
  amount: string
}

export interface BetLocalSerialized {
  option: BettingOption
  number: NUMBERS
  amount: string
  directionX: DIRECTION_X
  directionY: DIRECTION_Y
}

export interface BetDeserialized {
  option: BettingOption
  number: NUMBERS
  amount: BigNumber
}

export type MultiCallResponse<T> = T | null

export enum ChainId {
  LOCALHOST = 31337,
  ETHEREUM = 1,
  GOERLI = 5,
  BSC = 56,
  BSC_TESTNET = 97,
  ZKSYNC_TESTNET = 280,
  ZKSYNC = 324,
  POLYGON_ZKEVM = 1101,
  POLYGON_ZKEVM_TESTNET = 1442,
  ARBITRUM_ONE = 42161,
  ARBITRUM_GOERLI = 421613,
  LINEA_TESTNET = 59140,
}

export interface IRatRoulette {
  isRoundOpen: boolean
  bettingRound: number
  minBet: string
  maxBet: string
}
