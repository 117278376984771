import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
  RatRouletteState,
  SerializedRatRoulettePublicData,
  SerializedRatRouletteUserData,
} from "../types"
import { fetchPublicRatRouletteValue } from "./fetchRatRoulette"
import { fetchUserRatRouletteData } from "./fetchUserRatRoulette"
import { Address } from "viem"
import { AppState } from ".."

const initialState: RatRouletteState = Object.freeze({
  publicData: {
    isRoundOpen: false,
    bettingRound: 0,
    minBet: null,
    maxBet: null,
  },
  userData: {
    account: {
      balance: null,
      totalBet: null,
      totalWon: null,
    },
    bets: {
      placed: [],
      pendingWins: [],
    },
  },
})

export const fetchPublicRatRouletteDataAsync =
  createAsyncThunk<SerializedRatRoulettePublicData>(
    "ratRoulette/fetchPublicRatRouletteData",
    async (arg, { getState }) => {
      const state = getState()

      // const potteryVaultAddress = (state as AppState).ratRoulette.lastVaultAddress
      // const [publicPotteryData, totalLockedValue, latestRoundId] = await Promise.all([

      const [publicRatRouletteData] = await Promise.all([
        fetchPublicRatRouletteValue(),
        // fetchTotalLockedValue(potteryVaultAddress),
        // fetchLatestRoundId(),
      ])
      return { ...publicRatRouletteData }
      // return { ...publicPotteryData, ...totalLockedValue, ...latestRoundId }
    },
  )

export const fetchUserRatRouletteDataAsync = createAsyncThunk<
  SerializedRatRouletteUserData,
  { account: Address}
>("ratRoulette/fetchUserRatRouletteData", async ( account, { getState }) => {
  const state = getState()
  const selectedRound = (state as AppState).bets.selectedRound

  const [userDataRatRouletteData] = await Promise.all([
    fetchUserRatRouletteData(account as Address, selectedRound),
  ])

  return { ...userDataRatRouletteData }
})

export const RatRouletteSlice = createSlice({
  name: "ratRoulette",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPublicRatRouletteDataAsync.fulfilled,
      (state, action: PayloadAction<SerializedRatRoulettePublicData>) => {
        state.publicData = { ...action.payload }
      },
    ),
      builder.addCase(
        fetchUserRatRouletteDataAsync.fulfilled,
        (state, action: PayloadAction<SerializedRatRouletteUserData>) => {
          state.userData = { ...action.payload }
        },
      )
  },
})

export default RatRouletteSlice.reducer
