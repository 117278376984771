import { useCallback } from 'react'
import { useAppDispatch } from '../../../state'
import { useToast } from '../../../contexts/ToastsContext'
import useCatchTxError from '../../../hooks/useCatchTxError'
import { useRatRouletteContract } from '../../../hooks/useContract'
import { fetchPublicRatRouletteDataAsync, fetchUserRatRouletteDataAsync } from '../../../state/roulette'
import { useAccount } from 'wagmi'
import { getDecimalAmount } from '../../../utils/formatBalance'

export const useDeposit = (amount: number) => {
  const dispatch = useAppDispatch()

  const { address: account } = useAccount()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: isPending } = useCatchTxError()
  const contract = useRatRouletteContract()
  const amountWei = getDecimalAmount(amount)

  const handleDeposit = useCallback(async () => {
     const receipt = await fetchWithCatchTxError(() =>
      contract.write.deposit([], 
        {
          value: amountWei
        }),
    )

    if (receipt?.status) {
      toastSuccess(
        'Success!',
        'Deposit succsess'
        // <ToastDescriptionWithTx txHash={receipt.transactionHash}>
        //   {'Your funds have been staked in the pool'}
        // </ToastDescriptionWithTx>,
      )
      dispatch(fetchPublicRatRouletteDataAsync())
      dispatch(fetchUserRatRouletteDataAsync(account))
    }
  // }, [amount, fetchWithCatchTxError, contract.write, account, chain, toastSuccess, t, dispatch])
  }, [fetchWithCatchTxError, contract.write, dispatch, account, amountWei])

  return { isPending, handleDeposit }
}