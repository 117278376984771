import { useCallback } from 'react'
import { useAppDispatch } from '../../../state'
// import { useTranslation } from '@pancakeswap/localization'
// import BigNumber from 'bignumber.js'
import { useToast } from '../../../contexts/ToastsContext'
import useCatchTxError from '../../../hooks/useCatchTxError'
// import { ToastDescriptionWithTx } from 'components/Toast'
import { useRatRouletteContract } from '../../../hooks/useContract'
import { Address, useAccount } from 'wagmi'
import { useBetsData } from '../../../state/bets/hooks'
import RatRouletteABI from '../../../abis/ratroulette.json'
import { encodeFunctionData } from 'viem'
import { fetchPublicRatRouletteDataAsync, fetchUserRatRouletteDataAsync } from '../../../state/roulette'
import { clearBets } from '../../../state/bets'
import { BetLocalSerialized } from '../../../types'
// import { fetchPotteryUserDataAsync } from 'state/pottery'
// import { DEFAULT_TOKEN_DECIMAL } from 'config'

export const usePlaceBets = () => {
  const dispatch = useAppDispatch()
  const { betsData } = useBetsData()
  const { address: account } = useAccount()
  
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: isPending } = useCatchTxError()
  const contract = useRatRouletteContract()

  const handlePlaceBets = useCallback(async () => {
    let outsideBetCalls = []
    let insideBetCalls = []
    console.log("handlePlaceBets pendingOutsideBets:", betsData.pendingOutsideBets)
    console.log("handlePlaceBets pendingInsideBets:", betsData.pendingInsideBets)
    
    {betsData.pendingOutsideBets.map((bet) => (
      outsideBetCalls.push(
        encodeFunctionData({
          abi: RatRouletteABI,
          functionName: 'makeOutsideBet',
          args: [bet]
        })
      )
    ))}

    {betsData.pendingInsideBets.map((bet) => (
      insideBetCalls.push(
        encodeFunctionData({
          abi: RatRouletteABI,
          functionName: 'makeInsideBet',
          args: [bet]
        })
      )
    ))}

     const receipt = await fetchWithCatchTxError(() =>
      contract.write.multicall([[...outsideBetCalls, ...insideBetCalls]]),
      // contract.write.multicall([...calls]),
    )

    if (receipt?.status) {
      toastSuccess(
        'Success',
        'Bets have been placed'
        // <ToastDescriptionWithTx txHash={receipt.transactionHash}>
        //   {'Your funds have been staked in the pool'}
        // </ToastDescriptionWithTx>,
      )
      // dispatch(fetchPotteryUserDataAsync(account))
      dispatch(clearBets())
      dispatch(fetchPublicRatRouletteDataAsync())
      dispatch(fetchUserRatRouletteDataAsync(account))
    }
  }, [fetchWithCatchTxError, contract.write, dispatch, betsData.pendingOutsideBets, betsData.pendingInsideBets, account])

  return { isPending, handlePlaceBets }
}