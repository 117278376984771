import { useEffect } from 'react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { configureChains, createConfig } from 'wagmi'
import { watchAccount, getAccount, getNetwork, PublicClient } from '@wagmi/core'
import { bsc } from 'wagmi/chains'

import { useAppDispatch } from "./state"
import { updateAccount } from "./features/web3Provider/web3Slice"
import { useRatRouletteFetch } from './state/roulette/hooks';

const testnet = {
  id: 31337,
  name: "BNB Smart Chain",
  network: "bsc",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB",
  },
  rpcUrls: {
    default: {
      http: ["http://localhost:8545"],
    },
    public: {
      http: ["http://localhost:8545"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "BscScan",
      url: "https://bscscan.com",
    },
    default: {
      name: "BscScan",
      url: "https://bscscan.com",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 15921452,
    },
  },
};
// const chains = [bsc]
const chains = [testnet]
export const projectId = '0b437640c35792398a8406fa7b195bf7'

export const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})

export const ethereumClient = new EthereumClient(wagmiConfig, chains)

export const AccountWatcher = ({ children }) => {
    const dispatch = useAppDispatch();
    useRatRouletteFetch()
    
    useEffect(() => {
      const unwatchAccounts = watchAccount(accountChangeCallback);
  
      return () => {
        // Clean up the watcher when the component unmounts
        unwatchAccounts();
      };
    }, []);
  
    async function accountChangeCallback() {
      const account = getAccount();
      const { chain, chains } = getNetwork();
      dispatch(updateAccount(account));
    }
  
    return children
};
