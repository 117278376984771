import React, { useEffect, useState } from 'react'
import BetBlock from '../BettingTable/components/BetBlock/betblock'
import Chip from '../BettingTable/components/Chip/chip'
import { NUMBERS, BettingOption, DIRECTION_X, DIRECTION_Y, DIRECTION } from "../../types"
import styles from "./BettingTable.module.css"
import { useAppDispatch } from '../../state'
import { clearHighlights, updateBet, updateHighlighedNumbersV2, updateHighlightInsideBet } from "../../state/bets/index"
import { useRatRouletteData } from '../../state/roulette/hooks'
import { betBlockHoverLimit, isBottomEdge, isBottomMiddle, isLeft, isLeftBottomCorner, isLeftEdge, isLeftMiddle, isLeftTopCorner, isRight, isRightBottomCorner, isRightEdge, isRightMiddle, isTop, isTopEdge, isTopMiddle } from '../../utils'
import { useToast } from '../../contexts'
import { useBetsData } from '../../state/bets/hooks'
import BetsPlaced from './components/BetsPlaced/BetsPlaced'
import AccountCard from './components/AccountCard/accountCard'
import { betBlockSize } from '../../config/constants'
import ControlPanel from './components/ControlPanel/controlPanel'
import Stream from '../Stream/stream'

const BettingTable: React.FC<React.PropsWithChildren> = () => {
  const dispatch = useAppDispatch()

  const { userData } = useRatRouletteData()
  const { betsData } = useBetsData()

  const [betHover, setBetHover] = useState<BettingOption>(BettingOption.Straight);
  const [betHoverNumber, setBetHoverNumber] = useState<NUMBERS>(NUMBERS.ZERO);
  const [betHoverDirection, setBetHoverDirection] = useState<DIRECTION>(DIRECTION.HORIZONTAL);
  const [betHoverDirectionX, setBetHoverDirectionX] = useState<DIRECTION_X>(DIRECTION_X.LEFT);
  const [betHoverDirectionY, setBetHoverDirectionY] = useState<DIRECTION_Y>(DIRECTION_Y.BOTTOM);
  const [highLightedNumbers, setHighLightedNumbers] = useState<Number[]>([]);

  useEffect(() => {
    setHighLightedNumbers(betsData.highlightedNumbers)
  }, [betsData.highlightedNumbers]); //

  const [betAmount, setBetAmount] = useState<number>(0.1)

  const { toastError, toastSuccess } = useToast()
  
  const shouldHighlight = (_selectedNumber: number) => highLightedNumbers.includes(_selectedNumber);


  function handleOutsideBetHover(event: Event){
    
    const selectedOutsideBet = event.target.getAttribute('data-type-base')
    const eventType = event.type;
    if (eventType === 'mouseenter') {
      dispatch(updateHighlighedNumbersV2(selectedOutsideBet))
      setBetHover(selectedOutsideBet)
    } else if (eventType === 'mouseleave') {
      dispatch(clearHighlights())
    }
  }

  function handleZeroMouseMove(e: Event){
    
    const betNumber = Number(e.target.getAttribute('data-number'))
    
    setBetHoverNumber(betNumber)

    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY - rect.top;  //y position within the element.

    if (betNumber == NUMBERS.ZERO && isBottomMiddle(Number(x), Number(y))){
      setBetHover(BettingOption.Split_Vertical)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }
    if (betNumber == NUMBERS.DOUBLE_ZERO && isTopMiddle(Number(x), Number(y))){
      setBetHover(BettingOption.Split_Vertical)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }

    if (betNumber == NUMBERS.ZERO && isLeftBottomCorner(Number(x), Number(y)) ||
        betNumber == NUMBERS.DOUBLE_ZERO && isLeftTopCorner(Number(x), Number(y))){
          setBetHover(BettingOption.Basket)
          // highLightBasket()
          // dispatch(updateHighlighedNumbersV2(BettingOption.Basket))
          return
      }

    setBetHover(BettingOption.Straight)
    dispatch(clearHighlights())
    // if zero and bottom center, split bet
    // if doublezero and top center, split bet
    // else just the betType
  }
  
  function handleUpdateBetAmount(event: Event){
    const betAmount = event.target.getAttribute('data-amount')
    setBetAmount(Number(betAmount))
  }

  function createBetInstance(){
    return {
      amount: betAmount.toString(),
      number: betHoverNumber,
      option: betHover,
      directionX: betHoverDirectionX,
      directionY: betHoverDirectionY
    }
  }

  function handlePlaceBet(event: Event){

    if (betAmount == 0){
      toastError(`No bet amount`, 'Select a amount to bet!')
      return
    }

    const confirmedBets = userData.bets.placed
    const alreadyPlaced = confirmedBets.some((bet) => (bet.option === betHover) && (bet.number == betHoverNumber));

    if (alreadyPlaced){
      toastError(`No double bets`, 'Bet has already been made!')
      return
    }

    dispatch(updateBet(createBetInstance()))
  }
  // move to config. make table dynamic
  
  function handleMouseMove(e: Event){
    const betNumber = Number(e.target.getAttribute('data-number'))
    setBetHoverNumber(betNumber)

    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left; //x position within the element.
    var y = e.clientY - rect.top;  //y position within the element.
    // console.log("Left? : " + x + " ; Top? : " + y + ".");

    // I don't think this is needed anymore
    if (isTop(y)){
      setBetHoverDirectionY(DIRECTION_Y.TOP)
    } else {
      setBetHoverDirectionY(DIRECTION_Y.BOTTOM)
    }

    if (isLeft(x)){
      setBetHoverDirectionX(DIRECTION_X.LEFT)
    }
    if (isRight(x)){
      setBetHoverDirectionX(DIRECTION_X.RIGHT)
    }

    if (betNumber <= NUMBERS.TWO && isRightMiddle(Number(x), Number(y))){
      setBetHover(BettingOption.Basket)
      dispatch(updateHighlighedNumbersV2(BettingOption.Basket))
      return
    }
    
    // Split Bet checks
    if (isTopMiddle(Number(x), Number(y)) && !isTopEdge(betNumber)){
      setBetHover(BettingOption.Split_Vertical)
      setBetHoverDirection(DIRECTION.HORIZONTAL)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }
    if (isBottomMiddle(Number(x), Number(y)) && !isBottomEdge(betNumber)){
      setBetHover(BettingOption.Split_Vertical)
      setBetHoverDirection(DIRECTION.HORIZONTAL)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }
    if (isLeftMiddle(Number(x), Number(y)) && !isLeftEdge(betNumber)){
      setBetHover(BettingOption.Split_Horizontal)
      setBetHoverDirection(DIRECTION.VERTICAL)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }
    if (isRightMiddle(Number(x), Number(y)) && !isRightEdge(betNumber)){
      setBetHover(BettingOption.Split_Horizontal)
      setBetHoverDirection(DIRECTION.VERTICAL)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }

    // Street Check
    if(isBottomEdge(betNumber) && isBottomMiddle(x,y)){//something wrong here
      setBetHover(BettingOption.Street)
      dispatch(updateHighlightInsideBet(createBetInstance()))
      return
    }

    // Corner checks
    if (x < betBlockHoverLimit && y < betBlockHoverLimit){
      if (!isTopEdge(betNumber) && !isLeftEdge(betNumber)){
        setBetHover(BettingOption.Corner)
        dispatch(updateHighlightInsideBet(createBetInstance()))
        return
      }
    }
    if (x > (betBlockSize - betBlockHoverLimit) && y < betBlockHoverLimit){
        if (!isTopEdge(betNumber) && !isRightEdge(betNumber)){
          setBetHover(BettingOption.Corner)
          dispatch(updateHighlightInsideBet(createBetInstance()))
          return
      }
    }
    if (x < betBlockHoverLimit && y > (betBlockSize - betBlockHoverLimit)){
      if (!isBottomEdge(betNumber) && !isLeftEdge(betNumber)){
        setBetHover(BettingOption.Corner)
        dispatch(updateHighlightInsideBet(createBetInstance()))
        return
      }
    }
    if (x > (betBlockSize - betBlockHoverLimit) && y > (betBlockSize - betBlockHoverLimit)){
      if (!isBottomEdge(betNumber) && !isRightEdge(betNumber)){
        setBetHover(BettingOption.Corner)
        dispatch(updateHighlightInsideBet(createBetInstance()))
        return
      }
    }

    // SixLine checks
    if(isBottomEdge(betNumber)){
      if (isLeftBottomCorner(x,y) && !isLeftEdge(betNumber)){
        setBetHover(BettingOption.SixLine)
        dispatch(updateHighlightInsideBet(createBetInstance()))
        return
      }
      if (isRightBottomCorner(x,y) && !isRightEdge(betNumber)){
        setBetHover(BettingOption.SixLine)
        dispatch(updateHighlightInsideBet(createBetInstance()))
        return
      }
    }

    setBetHover(BettingOption.Straight)
    dispatch(clearHighlights())

  }

  function onMouseExit(){
    dispatch(clearHighlights())
  }

  return (
    <div style={{position: 'relative'}}>
      {/* <AccountCard></AccountCard> */}
      {/* <Stream></Stream> */}

      {/* <h1>Bet Number: {betHoverNumber}</h1>
      <h1>Bet Type: {getBettingOptionName(betHover)}</h1>
      <h1>Bet Amount: {betAmount}</h1>
      <h1>Bet Direction: {betHoverDirection ? 'HORIZONTAL' : 'VERTICAL'}</h1>
      <h1>Bet Direction X: {betHoverDirectionX ? 'RIGHT' : 'LEFT'}</h1>
      <h1>Bet Direction Y: {betHoverDirectionY ? 'DOWN' : 'UP'}</h1> */}
      <div className={`${styles.bettingTableContainer}`}>

        <div>
          Current Round: 00 | status
        </div>
        <div>
          Winning Number 00
        </div>

        <BetsPlaced></BetsPlaced>
        <div className={`${styles.flex}`} id='top'>
          <div>
            <BetBlock number={1} betTypeBase={BettingOption.Columns1} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
            <BetBlock number={2} betTypeBase={BettingOption.Columns2} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
            <BetBlock number={3} betTypeBase={BettingOption.Columns3} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          </div>
        <div>
        <div className={`${styles.flex2}`}>
          {Array.from({ length: 36 }, (_, i) => i + 1).map((number) => (
            <BetBlock 
            number={number} 
            highLighted={shouldHighlight(number)}
            onMoveCallback={handleMouseMove}
            onMouseLeaveCallback={onMouseExit}
            onClickCallback={handlePlaceBet}
            />
          ))}
        </div>
      </div>
        <div>
          <BetBlock number={NUMBERS.ZERO} highLighted={shouldHighlight(NUMBERS.ZERO)} onMouseLeaveCallback={onMouseExit} onMoveCallback={handleZeroMouseMove} onClickCallback={handlePlaceBet}/>
          <BetBlock number={NUMBERS.DOUBLE_ZERO} highLighted={shouldHighlight(NUMBERS.DOUBLE_ZERO)} onMouseLeaveCallback={onMouseExit} onMoveCallback={handleZeroMouseMove} onClickCallback={handlePlaceBet}/>
        </div>
      </div>
      <div id='bottom'>
        <div id='dozens' className={styles.dozens}>
          <BetBlock number={3} betTypeBase={BettingOption.Dozens3} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={2} betTypeBase={BettingOption.Dozens2} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={1} betTypeBase={BettingOption.Dozens1} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
        </div>
        <div id='outsideBets' className={styles.outside}>
          <BetBlock number={1} betTypeBase={BettingOption.High} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={1} betTypeBase={BettingOption.Odd} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={2} betTypeBase={BettingOption.Black} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={1} betTypeBase={BettingOption.Red} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={2} betTypeBase={BettingOption.Even} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
          <BetBlock number={2} betTypeBase={BettingOption.Low} onHoverCallback={handleOutsideBetHover} onClickCallback={handlePlaceBet} onMouseLeaveCallback={onMouseExit}/>
        </div>
      </div>
      
      
      </div>

      <div>
        <div id='chips'>
          <Chip amount={0.1} bgNumber={1} isSelected={betAmount==10} onClickCallback={handleUpdateBetAmount}></Chip>
          <Chip amount={0.2} bgNumber={2} isSelected={betAmount==20} onClickCallback={handleUpdateBetAmount}></Chip>
          <Chip amount={0.5} bgNumber={3} isSelected={betAmount==50} onClickCallback={handleUpdateBetAmount}></Chip>
          <Chip amount={1} bgNumber={4} isSelected={betAmount==100} onClickCallback={handleUpdateBetAmount}></Chip>
        </div>
      </div>
      </div>
      )
    }
  
    export default BettingTable
