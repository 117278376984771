import { useCallback } from "react";
import { ToastWrap } from "../../components/Toast";
import { useToast } from "./useToast";

const ToastListener = () => {
  const { toasts, remove } = useToast();

  const handleRemove = useCallback((id: string) => remove(id), [remove]);


  return <ToastWrap toasters={toasts} onRemove={handleRemove}/>;
};

export default ToastListener;