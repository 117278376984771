import BigNumber from 'bignumber.js'
import RatRouletteABI from '../../abis/ratroulette.json'
import { publicClient } from '../../provider'
import { getRatRouletteAddress } from '../../utils/addressHelpers'

const BIG_ZERO = new BigNumber(0)

export const fetchPublicRatRouletteValue = async () => {

  try {
    const calls = [
      {
        abi: RatRouletteABI,
        address: getRatRouletteAddress(31337),
        functionName: 'isRoundOpen',
      },
      {
        abi: RatRouletteABI,
        address: getRatRouletteAddress(31337),
        functionName: 'BettingRound',
      },
      {
        abi: RatRouletteABI,
        address: getRatRouletteAddress(31337),
        functionName: 'minBet',
      },
      {
        abi: RatRouletteABI,
        address: getRatRouletteAddress(31337),
        functionName: 'maxBet',
      },
    ]

    const client = publicClient(31337)
    const [ isRoundOpen, bettingRound, minBet, maxBet ] = await client.multicall({
        contracts: calls,
        allowFailure: false,
        })
    return {
      isRoundOpen: isRoundOpen,
      bettingRound: Number(bettingRound),
      minBet: new BigNumber(minBet.toString()).toJSON(),
      maxBet: new BigNumber(maxBet.toString()).toJSON(),
    }
  } catch (e) {
    console.error("getRatRoulette", e)
    return {
      isRoundOpen: false,
      bettingRound: 0,
      minBet: BIG_ZERO.toJSON(),
      maxBet: BIG_ZERO.toJSON(),
    }
  }
}