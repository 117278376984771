import React from 'react'
import styles from "./betblock.module.css"
import { NUMBERS, BettingOption } from "../../../../types"
import CHIP from "../../../../../public/images/chip1.png"

interface BetBlockProps {
    number: NUMBERS
    betTypeBase?: BettingOption
    highLighted?: boolean
    onHoverCallback?: (
      event: any /*React.MouseEvent<HTMLDivElement>*/
    ) => void;
    onMouseLeaveCallback: (
      event: any /*React.MouseEvent<HTMLDivElement>*/
    ) => void;
    onMoveCallback? : (
      event: any /*React.MouseEvent<HTMLDivElement>*/
    ) => void;
    onClickCallback: (
      event: any /*React.MouseEvent<HTMLDivElement>*/
    ) => void;
    isBetted?: boolean,
    chipCount?: number
}

function calculateColour(number: NUMBERS, betTypeBase: BettingOption){
  if (betTypeBase == BettingOption.Columns1 || betTypeBase == BettingOption.Columns2 || betTypeBase == BettingOption.Columns3){
    return 'green'
  }
  if (betTypeBase == BettingOption.Dozens1 || betTypeBase == BettingOption.Dozens2 || betTypeBase == BettingOption.Dozens3){
    return 'green'
  }
  if (betTypeBase == BettingOption.Red || betTypeBase == BettingOption.Black){
    return 'green'
  }
  if (betTypeBase == BettingOption.High || betTypeBase == BettingOption.Low){
    return 'green'
  }
  if (betTypeBase == BettingOption.Odd || betTypeBase == BettingOption.Even){
    return 'green'
  }

  if ((number >= 1 && number <= 10) || (number >= 19 && number <= 28)) {
      return (number % 2 != 0) ? 'red' : 'black';
  }
  if ((number >= 11 && number <= 18) || (number >= 29 && number <= 36)) {
      return (number % 2 == 0) ? 'red' : 'black';
  }
  if ((number == 0) || number == 37) {
    return 'green'
  }
}

function calculateText(number: NUMBERS, betBaseType?: BettingOption){
  if (betBaseType == BettingOption.Columns1){return '2:1'}
  if (betBaseType == BettingOption.Columns2){return '2:1'}
  if (betBaseType == BettingOption.Columns3){return '2:1'}
    
  if (betBaseType == BettingOption.Dozens1){return '1ST 12'}
  if (betBaseType == BettingOption.Dozens2){return '2ND 12'}
  if (betBaseType == BettingOption.Dozens3){return '3RD 12'}

  if (betBaseType == BettingOption.Red){return 'RED'}
  if (betBaseType == BettingOption.Black){return 'BLACK'}

  if (betBaseType == BettingOption.Odd){return 'ODD'}
  if (betBaseType == BettingOption.Even){return 'EVEN'}

  if (betBaseType == BettingOption.Low){return '1 - 18'}
  if (betBaseType == BettingOption.High){return '19 - 36'}

  if (number == NUMBERS.DOUBLE_ZERO){return '00'}

  return number

}

export default function betBlock({number, betTypeBase, highLighted, onHoverCallback, onMouseLeaveCallback, onMoveCallback, onClickCallback, isBetted}: BetBlockProps) {

//   .betBlock {
//     width: 70px;
//     height: 70px;
//     line-height: 70px;
//     text-align: center;
//     border: 1px solid gold;
//     transition: all 0.3s;
//     position: relative;
// }

  return (
    <div 
      data-number={number}
      data-type-base={betTypeBase}
      className={`${styles.betBlock} ${styles[`betBlock__${calculateColour(number, betTypeBase)}`]} ${highLighted ? styles.highlight : ''}`}                 
      onMouseEnter={onHoverCallback}
      onMouseLeave={onMouseLeaveCallback}
      onMouseMove={onMoveCallback}
      onClick={onClickCallback}
    >
        {isBetted ? <img src={CHIP} alt="" /> : ''}
        {calculateText(number, betTypeBase)}
        {/* <div className={styles.numberBox}>{calculateText(number, betTypeBase)}</div> */}
        
    </div>
  )
}
