import { useCallback } from 'react'
import { useAppDispatch } from '../../../state'
import { useToast } from '../../../contexts/ToastsContext'
import useCatchTxError from '../../../hooks/useCatchTxError'
import { useRatRouletteContract } from '../../../hooks/useContract'
import { fetchPublicRatRouletteDataAsync, fetchUserRatRouletteDataAsync } from '../../../state/roulette'
import { useAccount } from 'wagmi'

export const useSetRoundWinner = () => {
  const dispatch = useAppDispatch()

  const { address: account } = useAccount()
  const { toastSuccess } = useToast()
  const { fetchWithCatchTxError, loading: isPending } = useCatchTxError()
  const contract = useRatRouletteContract()

  const handleSetRoundWinner = useCallback(async () => {
     const receipt = await fetchWithCatchTxError(() =>
      contract.write.setRoundWinner(['1']),
    )

    if (receipt?.status) {
      toastSuccess(
        'Success!',
        'Round has been set'
      )
      // dispatch(fetchPotteryUserDataAsync(account))
      dispatch(fetchPublicRatRouletteDataAsync())
      dispatch(fetchUserRatRouletteDataAsync(account))
    }
  // }, [amount, fetchWithCatchTxError, contract.write, account, chain, toastSuccess, t, dispatch])
  }, [fetchWithCatchTxError, contract.write, dispatch, account])

  return { isPending, handleSetRoundWinner }
}